
// $(document).on('turbolinks:load', () => {
//   setValidationRules();
// });

var rulesForEntries = {}

const generalRules = {
  currency: {
    required: !0
  },
  email: {
    required: !0,
    email: !0
  },
  dropdown: {
    required: !0,
  },
  zipcode: {
    required: !0,
    rangelength: [5,6],
  },
  phone: {
    required: !0,
    rangelength: [10,10],
    digits: true,
  },
  fiscal: {
    required: !0,
    rangelength: [13,13],
  },
  radio: {
    required: !0,
  },
  name: {
    required: !0,
  },
  address: {
    required: !0,
  },
  default: {
    required: !0,
  },
}

const generalMessages = {
  email: "Ingrese un email válido",
  phone: {
    required: "Ingrese un número de teléfono válido",
    rangelength: "Ingrese un número válido a 10 dígitos",
    digits: "Ingrese únicamente números",
  },
  fiscal: {
    required: "Ingrese un identificador válido",
    rangelength: "Ingrese 13 caracteres",
  },
  zipcode: {
    required: "Ingrese un C.P. válido",
    rangelength: "Ingrese entre 5 y 6 caracteres",
  },
  dropdown: "Seleccione una opción",
  currency: "Ingrese un monto válido",
  zipcode: "Ingrese un CP válido",
  address: "El campo es requerido",
  default: "El campo es requerido",
}

const defaultValidationParams = {
  debug: true,
  errorClass: "invalid-feedback animated fadeInDown",
  errorElement: "div",
  errorPlacement: function(e, a) {
      jQuery(a).parents(".form-group").append(e)
  },
  highlight: function(e) {
      jQuery(e).closest(".form-group").removeClass("is-invalid").addClass("is-invalid")
  },
  success: function(e) {
      jQuery(e).closest(".form-group").removeClass("is-invalid"), jQuery(e).remove()
  },
  rules: {},
  messages: {},
}

var currentValidator = null

const validateForm = function(form_id, callback) {
  const jq = `#${form_id}`
  const params = getValidationParams(jq)
  const submitHandler = function() {
    callback()
  }
  currentValidator = $(jq).validate({ ...defaultValidationParams, ...params, submitHandler })
  return currentValidator.form()
}

const resetValidator = function() {
  if(currentValidator) {
    currentValidator.resetForm()
  }
}

function getValidationParams(jq) {
  const sims = $(jq).find('.sim')
  let rules = {}
  let messages = {}
  sims.each(function () {
    const rule = rulesForEntries[this.id]
    if (! rule) {
      rules[this.id] = generalRules.default
      messages[this.id] = generalMessages.default
    }
    else {
      rules[this.id] = generalRules[rule] || generalMessages.default
      messages[this.id] = generalMessages[rule] || generalMessages.default
    }
  });
  return { rules, messages }
}

window.initValidations = function(validations) {
  validations.forEach(val => {
    rulesForEntries[val.code] = val.rule
  });
}

export { validateForm, resetValidator }
