import { validateForm, resetValidator } from "./validations.js";

//
var current_step = 0;
var total_steps = 0;
var recaptcha_ready = false
var recaptcha_token = ""

const errorMessages = {
  recaptcha_invalid: "La validación 'No soy un robot' ha fallado, por favor intente de nuevo",
  odoo_failed: "Ha ocurrido un error con la operación, intente más tarde.",
  default: "Ha ocurrido un error con la operación, intente más tarde.",
}

$(document).on('turbolinks:load', () => {
  startSimulator();
});

window.initSimulatorVars = function(steps_count) {
  total_steps = steps_count
}

function startSimulator() {
  current_step = 0;
  activateStep(0);
  setRecaptchaStatus(false)
  // showRecaptcha()
  // showSuccessBanner()
}

// activate a specific step
function activateStep(idx) {
  const id = `#st_${idx}`;
  jQuery(id).removeClass("step-inactive");
  // document.getElementById(id).classList.remove('step-inactive');
}

function deactivateStep(idx) {
  const id = `#st_${idx}`;
  jQuery(id).removeClass("step-inactive").addClass("step-inactive")
  // document.getElementById(id).classList.remove('step-inactive');
  // document.getElementById(id).classList.add('step-inactive');
}

// functions accessible from html
window.onStepNextAction = function(id) {
  //
  // resetValidator()
  //
  const validated = validateForm(id, onNext)
  if (! validated) {
    return
  }
  //
  onNext()
}

function onNext(){
  if ((current_step + 1) >= total_steps) {
    // show recaptcha
    deactivateStep(current_step)
    showRecaptcha()
    return
  }
  //
  const next_step = current_step + 1
  deactivateStep(current_step)
  activateStep(next_step)
  //
  current_step = next_step
}

function showRecaptcha() {
  jQuery("#recaptcha_container").removeClass("step-inactive")
  jQuery("#recaptcha_body").removeClass("step-inactive")
  jQuery("#submit_recaptcha").removeClass("step-inactive")
  jQuery("#text_err_message").removeClass("step-inactive").addClass("step-inactive")
}

function hideRecaptcha() {
  jQuery("#recaptcha_container").removeClass("step-inactive").addClass("step-inactive")
}

function showSuccessBanner() {
  jQuery("#success_banner").removeClass("step-inactive");
}

function showError(message) {
  jQuery("#go_back_button").removeClass("step-inactive")
  jQuery("#text_err_message").removeClass("step-inactive")
  jQuery("#text_err_message").text(message)
  jQuery("#recaptcha_body").removeClass("step-inactive").addClass("step-inactive")
  jQuery("#submit_recaptcha").removeClass("step-inactive").addClass("step-inactive")
  jQuery("#sending_loader").removeClass("step-inactive").addClass("step-inactive")
}

window.onStepPrevAction = function() {
  if (current_step < 1) {
    return
  }
  //
  const prev_step = current_step - 1
  deactivateStep(current_step)
  activateStep(prev_step)
  //
  current_step = prev_step
}

window.onRecaptchaPrev = function() {
  hideRecaptcha()
  activateStep(current_step)
}

window.onRecaptchaSubmit = async function() {
  if(recaptcha_ready) {
    hideSubmitButton()
    hideFinalPrevButton()
    showLoadingButton()
    await submitSimulator()
  }
}

window.recaptchaCallback = function(token) {
  recaptcha_token = token
  setRecaptchaStatus(true)
}

window.recaptchaExpired = function() {
  setRecaptchaStatus(false)
}

window.recaptchaErrored = function() {
  setRecaptchaStatus(false)
}

function setRecaptchaStatus(status) {
  recaptcha_ready = status
  document.getElementById("submit_recaptcha").disabled = !status;
}

function hideSubmitButton() {
  jQuery("#submit_recaptcha").removeClass("step-inactive").addClass("step-inactive")
}

function showLoadingButton() {
  jQuery("#sending_loader").removeClass("step-inactive")
}

function hideFinalPrevButton() {
  jQuery("#go_back_button").removeClass("step-inactive").addClass("step-inactive")
}

async function submitSimulator() {
  const steps = collectStepResponses()
  // return
  const responses = JSON.stringify(steps)
  const formData = new FormData()
  formData.append('responses', responses);
  formData.append('recaptcha', recaptcha_token);

  window.Rails.ajax({
    url: "/simulator_submit",
    type: "POST",
    data: formData,
    success: function(data) {
      hideRecaptcha()
      showSuccessBanner()
    },
    error: function(data) {
      handleError(data)
    }
  })
}

function handleError(err) {
  const { err_code } = err
  const message = errorMessages[err_code] || errorMessages.default
  showError(message)
}

function collectStepResponses() {
  let steps = []
  jQuery(".sim-form").each(function () {
    const formData = new FormData(this);
    const formProps = Object.fromEntries(formData);
    steps.push(formProps)
  })
  return steps
}

window.onbeforeunload = function() {
  // FIXME: this is not being shown on the real alert
  const warning = "¿Desea recargar la página?\nLos datos ingresados en el simulador se perderán.";
  return warning;
};
